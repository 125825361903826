<template>
  <v-card outlined tile elevation="0" class="mt-6">
    <v-card-title class="subtitle-1 font-weight-medium">
      Azioni generiche
    </v-card-title>

    <v-card-actions class="ml-2">
      <v-btn
        class="white--text px-3"
        color="green darken-1"
        depressed
        tile
        type="submit"
        :loading="loading"
        @click.prevent="sendToPraticxBtn"
      >
        Forza invio a Praticx
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'BollatiGenericAction',
  emit: ['refresh-data'],
  computed: {
    ...mapState('payments', ['loading']),
  },
  methods: {
    ...mapActions('payments', ['sendToPraticx']),
    async sendToPraticxBtn() {
      await this.sendToPraticx();
      await this.$emit('refresh-data');
    }
  }
}
</script>
