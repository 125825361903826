var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTable',{ref:"table",attrs:{"module":"payments","title":"Elenco","headers":_vm.headers,"exportable":true,"sortby":'created_at',"sort-desc":true,"show-select":true,"item-key":"id","reset-select-if-reload":true},on:{"refresh":_vm.refresh},scopedSlots:_vm._u([{key:"item.ticket_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ticket_id || 'N/A')+" ")]}},{key:"item.placement_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.placement_mp ? item.placement_mp.user_id : item.placement_fn ? item.placement_fn.kenan_id : 'N/A')+" ")]}},{key:"item.activity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ticket && item.ticket.activity ? item.ticket.activity.macro : 'N/A')+" "),(item.ticket && item.ticket.activity)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")]):_vm._e(),_vm._v(" "+_vm._s(item.ticket && item.ticket.activity ? item.ticket.activity.category : '')+" "),(item.ticket && item.ticket.activity)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")]):_vm._e(),_vm._v(" "+_vm._s(item.ticket && item.ticket.activity ? item.ticket.activity.subcategory : '')+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.created_at,'DD/MM/YYYY HH:mm', '-'))+" ")]}},{key:"item.payment_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.payment_date,'DD/MM/YYYY', '-'))+" ")]}},{key:"item.payment_type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"my-6",attrs:{"small":"","color":_vm.getInfoByPaymentType(item.payment_type).color,"dark":""}},[_vm._v(" "+_vm._s(_vm.paymentTypeView[item.payment_type])+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getInfoByPaymentType(item.payment_type).sign)+" "+_vm._s(_vm._f("currency")(item.amount))+" ")]}},{key:"item.capital",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(_vm.getCapitalByItem(item)))+" ")]}},{key:"item.praticx_exported",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.praticxExportedRemappedToItems[item.praticx_exported])+" ")]}},{key:"item.exported",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.exportedByType(item.payment_type, item.exported))+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }