<template>
  <BaseSectionSearch @submit="onSubmit" @reset="onReset">
    <v-row dense>
      <FormUsers
        v-model="managed_by"
        label="Operatore"
        :use-username="true"
        hint="Ricerca per Operatore"
        dense
        :additional-filters="{ role_id: [1,2,3,4] }"
      />
      <FormItem
        v-model="payment_type"
        name="Tipologia"
        type="autocomplete"
        :values="payment_type_map"
        hint="Ricerca per Tipo Pagamento"
        dense
        :lg="4"
      />
      <FormItem
        v-model="exportedToPraticx"
        name="Esportato verso Praticx"
        type="autocomplete"
        :values="Object.values(praticxExportedRemapped)"
        hint="Ricerca per Esportato verso Praticx"
        dense
        :lg="4"
      />
    </v-row>
    <v-row dense>
      <FormItem
        v-model="macro"
        :values="
          macrosByContext({
            customerId: customer_id,
            category,
            subcategory,
          })
        "
        type="autocomplete"
        name="Cliente"
        chips
        deletable-chips
        dense
      />

      <FormItem
        v-model="category"
        :values="
          categoriesByContext({
            customerId: customer_id,
            macro,
            subcategory,
          })
        "
        type="select"
        name="Gruppo"
        dense
      />
      <FormItem
        v-model="subcategory"
        :values="
          subcategoriesByContext({
            customerId: customer_id,
            macro,
            category,
          })
        "
        type="autocomplete"
        name="Portfolio"
        chips
        deletable-chips
        dense
      />
    </v-row>
    <v-row>
      <FormItem
        v-model="created_at"
        type="datepicker"
        name="Data Inserimento"
        hint="Ricerca per Data Inserimento"
        dense
        range
        :xl="2"
      />
      <FormItem
        v-model="payment_date"
        type="datepicker"
        name="Data Pagamento"
        hint="Ricerca per Data Pagamento"
        dense
        range
        :xl="2"
      />
      <v-checkbox
        v-model="exportByType"
        name="Contabilizzato"
        label="Contabilizzato"
        :value="!exportByType"
      />
    </v-row>
  </BaseSectionSearch>
</template>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex';
import { createHelpers } from 'vuex-map-fields';

import BaseSectionSearch from '@components/structure/base-section-search.vue';
import FormItem from '@components/forms/FormItem.vue';
import FormUsers from '@components/forms/FormUsers.vue';

const { mapFields } = createHelpers({
  getterType: 'payments/getFiltersFields',
  mutationType: 'payments/SET_FILTER_FIELDS',
})

export default {
  name: 'BollatiSectionSearch',
  components: { FormItem, BaseSectionSearch, FormUsers },
  props: {
    praticxExportedRemapped: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      exportedToPraticx: [],
      exportByType: false,
    };
  },
  computed: {
    ...mapState('payments', ['payment_type_map', 'filters']),
    ...mapFields([
      'managed_by',
      'payment_type',
      'payment_date',
      'category',
      'macro',
      'subcategory',
      'created_at',
      'praticx_exported',
    ]),
    ...mapGetters('ticketsActivities', {
      macrosByContext: 'macrosByContext',
      categoriesByContext: 'categoriesByContext',
      subcategoriesByContext: 'subcategoriesByContext',
    }),
  },
  watch: {
    exportedToPraticx(values) {
      let praticxExported = [];
      values.forEach(value => {
        praticxExported = praticxExported.concat(this.praticxExportedRemapped[value].fieldsValue);
      });
      this.praticx_exported = praticxExported;
    },
  },
  methods: {
    ...mapMutations('payments', { setFilters: 'SET_FILTERS' }),
    onSubmit() {
      this.$emit('search');
    },
    onReset() {
      this.exportedToPraticx = [];
      this.exportByType = false;
      this.$emit('reset');
      this.onSubmit();
    }
  },
}
</script>

<style scoped>

</style>
