<template>
  <v-card outlined tile elevation="0" class="mt-6">
    <v-card-title class="subtitle-1 font-weight-medium">
      {{ title }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <FormItem
          v-model="valueSelected"
          name="Seleziona azione"
          type="select"
          :values="actionsFiltered"
        />
        <slot name="other-form-data" :action="actionSelected.value" />
        <v-col cols="3">
          <v-btn color="info" block depressed tile :disabled="disableRun" @click.prevent="submit">
            <v-icon left>
              mdi-cog-play-outline
            </v-icon>
            Esegui
          </v-btn>
          <span
          >Applica alle {{ selectedItems }} lavorazioni selezionate di
              {{ totalItems }} totali.</span
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import FormItem from '@components/forms/FormItem.vue';

export default {
  name: 'QuickActions',
  components: { FormItem },
  props: {
    title: {
      type: String,
      default: 'Azioni massive',
    },
    /**
     * @property {string} value - key
     * @property {string} text - label
     * @property {function} action - function to call
     * @property {boolean} hidden=false - if you want to hide the action
     * @property {boolean} disabled=false - if you want to disable the action
     * @property {boolean} canRun=true - if you want to disable the button that runs the action
     */
    actions: {
      type: Array,
      required: true,
      default: () => [],
      validator: (array) => array.every(el => el.value && el.text && el.action),
    },
    selectedItems: {
      type: Number,
      required: true,
      default: 0,
    },
    totalItems: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  emits: ['set-action-selected'],
  data() {
    return {
      valueSelected: null,
      actionSelected: {},
    };
  },
  computed: {
    actionsFiltered() {
      return this.actions.filter(obj => obj.hidden !== true);
    },
    disableRun() {
      return this.selectedItems === 0 || !this.valueSelected || this.actionSelected?.canRun === false;
    },
  },
  watch: {
    valueSelected() {
      if (!this.valueSelected) this.actionSelected = {};
      else this.reloadActionSelected();
    },
    actions() {
      this.reloadActionSelected();
    }
  },
  methods: {
    reloadActionSelected() {
      this.actionSelected = this.actions.find(obj => obj.value === this.valueSelected) || {};
      if (this.$listeners['set-action-selected']) {
        this.$emit('set-action-selected', this.actionSelected);
      }
    },
    async submit() {
      try {
        await this.actionSelected.action();
        this.valueSelected = null;
      } catch (e) {
        // if action error not reset valueSelected;
      }
    }
  }
}
</script>

<style scoped>

</style>
