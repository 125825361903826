<template>
  <BaseTable
    ref="table"
    v-model="selected"
    module="payments"
    title="Elenco"
    :headers="headers"
    :exportable=true
    :sortby="'created_at'"
    :sort-desc="true"
    :show-select=true
    item-key="id"
    :reset-select-if-reload="true"
    @refresh="refresh"
  >
    <template v-slot:item.ticket_id="{ item }">
      {{ item.ticket_id || 'N/A' }}
    </template>
    <template v-slot:item.placement_id="{ item }">
      {{ item.placement_mp ? item.placement_mp.user_id : item.placement_fn ? item.placement_fn.kenan_id : 'N/A' }}
    </template>
    <template v-slot:item.activity="{ item }">
      {{ item.ticket && item.ticket.activity ? item.ticket.activity.macro : 'N/A' }}
      <v-icon v-if="item.ticket && item.ticket.activity" small>mdi-chevron-right</v-icon>
      {{ item.ticket && item.ticket.activity ? item.ticket.activity.category : '' }}
      <v-icon v-if="item.ticket && item.ticket.activity" small>mdi-chevron-right</v-icon>
      {{ item.ticket && item.ticket.activity ? item.ticket.activity.subcategory : '' }}
    </template>
    <template v-slot:item.created_at="{ item }">
      {{ item.created_at | momentOr('DD/MM/YYYY HH:mm', '-') }}
    </template>
    <template v-slot:item.payment_date="{ item }">
      {{ item.payment_date | momentOr('DD/MM/YYYY', '-') }}
    </template>
    <template v-slot:item.payment_type="{ item }">
      <v-chip small :color="getInfoByPaymentType(item.payment_type).color" dark class="my-6">
        {{ paymentTypeView[item.payment_type] }}
      </v-chip>
    </template>
    <template v-slot:item.amount="{ item }">
      {{ getInfoByPaymentType(item.payment_type).sign }} {{ item.amount | currency }}
    </template>
    <template v-slot:item.capital="{ item }">
      {{ getCapitalByItem(item) | currency }}
    </template>
    <template v-slot:item.praticx_exported="{ item }">
      {{ praticxExportedRemappedToItems[item.praticx_exported] }}
    </template>
    <template v-slot:item.exported="{ item }">
      {{ exportedByType(item.payment_type, item.exported) }}
    </template>
  </BaseTable>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { reduce } from 'lodash';

import BaseTable from '@components/structure/base-table.vue';

export default {
  name: 'BollatiListFiltered',
  components: { BaseTable },
  props: {
    itemsSelected: {
      type: Array,
      default: () => [],
    },
    praticxExportedRemapped: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['change-items-selected'],
  data() {
    return {
      praticxExportedRemappedToItems: reduce(this.praticxExportedRemapped, (acc, el) => {
        el.fieldsValue.forEach(field => { acc[field] = el.text });
        return acc;
      }, {}),
    };
  },
  computed: {
    ...mapState('payments', {
      paymentTypeMapping: (state) => state.payment_type_map,
    }),
    ...mapGetters('payments', ['paymentTypeView']),
    headers() {
      return [
        {
          text: 'Operatore',
          value: 'managed_by',
        },
        {
          text: '#ID ticket',
          value: 'ticket_id',
        },
        {
          text: 'User/Kenan ID',
          value: 'placement_id',
        },
        {
          text: 'Portfolio',
          value: 'activity'
        },
        {
          text: 'Data e ora Inserimento',
          value: 'created_at',
        },
        {
          text: 'Data pagamento',
          value: 'payment_date',
        },
        {
          text: 'Tipologia pagamento',
          value: 'payment_type',
        },
        {
          text: 'Metodo pagamento',
          value: 'payment_method',
        },
        {
          text: 'Importo',
          value: 'amount',
        },
        {
          text: 'Di cui capitale',
          value: 'capital',
        },
        {
          text: 'Esportato verso Praticx',
          value: 'praticx_exported',
        },
        {
          text: 'Contabilizzato',
          value: 'exported',
        },
      ]
    },
    selected: {
      get() {
        return this.itemsSelected;
      },
      set(newValue) {
        this.$emit('change-items-selected', newValue);
      },
    }
  },
  methods: {
    ...mapMutations('payments', {
      setCtx: 'SET_CTX',
    }),
    getInfoByPaymentType(paymentType) {
      if ([1, 3, 5].includes(paymentType)) return { color: 'green', sign: '' };
      return { color: 'blue', sign: '-' };
    },
    getCapitalByItem(item) {
      if ([3, 4, 5, 6].includes(item.payment_type)) return 0;
      if ([1, 3, 5].includes(item.payment_type)) return (item.amount - item.refund_actual);
      return -(item.amount - item.refund_actual);
    },
    refresh(ctx) {
      this.setCtx(ctx);
    },
    exportedByType(paymentType, exported) {
      if ([1, 2].includes(paymentType)) return exported === 'exported' ? 'Si' : 'No';
      return 'N/A';
    }
  },
}
</script>

<style scoped>

</style>
