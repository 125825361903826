<template>
  <v-card outlined tile elevation="0" class="mt-5">
    <v-card-title>
      Riepilogo Bollati Filtrati
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-row>
        <v-col>
          <span class="d-block text--secondary">Totale pagamenti generici</span>
          <span class="text-h5 font-weight-bold ">
            {{ bollatiSummary.total_positive_entries | currency }}
          </span>
        </v-col>
        <v-col>
          <span class="d-block text--secondary">Totale storni generici</span>
          <span class="text-h5 font-weight-bold ">
            {{ bollatiSummary.total_negative_entries | currency }}
          </span>
        </v-col>
        <v-col>
          <span class="d-block text--secondary">Totale complessivo</span>
          <span class="text-h5 font-weight-bold ">
            {{ bollatiSummary.total_positive_entries - bollatiSummary.total_negative_entries | currency }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import { mapState } from 'vuex';

export default {
  name: 'BollatiSummary',
  computed: {
    ...mapState('payments', ['bollatiSummary']),
  },
}
</script>

<style scoped>
  .mt-5 {
    margin-bottom: 30px;
  }
</style>
