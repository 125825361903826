var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseSectionSearch',{on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('v-row',{attrs:{"dense":""}},[_c('FormUsers',{attrs:{"label":"Operatore","use-username":true,"hint":"Ricerca per Operatore","dense":"","additional-filters":{ role_id: [1,2,3,4] }},model:{value:(_vm.managed_by),callback:function ($$v) {_vm.managed_by=$$v},expression:"managed_by"}}),_c('FormItem',{attrs:{"name":"Tipologia","type":"autocomplete","values":_vm.payment_type_map,"hint":"Ricerca per Tipo Pagamento","dense":"","lg":4},model:{value:(_vm.payment_type),callback:function ($$v) {_vm.payment_type=$$v},expression:"payment_type"}}),_c('FormItem',{attrs:{"name":"Esportato verso Praticx","type":"autocomplete","values":Object.values(_vm.praticxExportedRemapped),"hint":"Ricerca per Esportato verso Praticx","dense":"","lg":4},model:{value:(_vm.exportedToPraticx),callback:function ($$v) {_vm.exportedToPraticx=$$v},expression:"exportedToPraticx"}})],1),_c('v-row',{attrs:{"dense":""}},[_c('FormItem',{attrs:{"values":_vm.macrosByContext({
          customerId: _vm.customer_id,
          category: _vm.category,
          subcategory: _vm.subcategory,
        }),"type":"autocomplete","name":"Cliente","chips":"","deletable-chips":"","dense":""},model:{value:(_vm.macro),callback:function ($$v) {_vm.macro=$$v},expression:"macro"}}),_c('FormItem',{attrs:{"values":_vm.categoriesByContext({
          customerId: _vm.customer_id,
          macro: _vm.macro,
          subcategory: _vm.subcategory,
        }),"type":"select","name":"Gruppo","dense":""},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}),_c('FormItem',{attrs:{"values":_vm.subcategoriesByContext({
          customerId: _vm.customer_id,
          macro: _vm.macro,
          category: _vm.category,
        }),"type":"autocomplete","name":"Portfolio","chips":"","deletable-chips":"","dense":""},model:{value:(_vm.subcategory),callback:function ($$v) {_vm.subcategory=$$v},expression:"subcategory"}})],1),_c('v-row',[_c('FormItem',{attrs:{"type":"datepicker","name":"Data Inserimento","hint":"Ricerca per Data Inserimento","dense":"","range":"","xl":2},model:{value:(_vm.created_at),callback:function ($$v) {_vm.created_at=$$v},expression:"created_at"}}),_c('FormItem',{attrs:{"type":"datepicker","name":"Data Pagamento","hint":"Ricerca per Data Pagamento","dense":"","range":"","xl":2},model:{value:(_vm.payment_date),callback:function ($$v) {_vm.payment_date=$$v},expression:"payment_date"}}),_c('v-checkbox',{attrs:{"name":"Contabilizzato","label":"Contabilizzato","value":!_vm.exportByType},model:{value:(_vm.exportByType),callback:function ($$v) {_vm.exportByType=$$v},expression:"exportByType"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }