<template>
  <v-container fluid>
    <BollatiSectionSearch
      ref="filters"
      :praticx-exported-remapped="praticxExportedRemapped"
      @search="search"
      @reset="reset"
    />

    <BollatiSummary />

    <BollatiListFiltered
      :items-selected="itemsSelected"
     :praticx-exported-remapped="praticxExportedRemapped"
     @change-items-selected="changeItemsSelected"
    />

    <QuickActions :actions="quickActions" :selected-items="itemsSelected.length" :total-items="totalRows" />

    <BollatiGenericAction @refresh-data="search" />
  </v-container>
</template>

<script>
import BollatiSectionSearch from '@components/bollati/BollatiSectionSearch.vue';
import BollatiSummary from '@components/bollati/BollatiSummary.vue';
import BollatiListFiltered from '@components/bollati/BollatiListFiltered.vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import QuickActions from '@components/structure/QuickActions.vue';
import BollatiGenericAction from '@components/bollati/BollatiGenericAction.vue';

export default {
  name: "Search",
  components: { BollatiGenericAction, QuickActions, BollatiListFiltered, BollatiSectionSearch, BollatiSummary },
  data() {
    return {
      itemsSelected: [],
      praticxExportedRemapped: {
        yes: { text: 'Si', value: 'yes', fieldsValue: ['exported', 'progress'] },
        no: { text: 'No', value: 'no', fieldsValue: ['null'] },
        waiting: { text: 'In fase di invio', value: 'waiting', fieldsValue: ['new'] },
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['getCurrentUsername']),
    ...mapState('payments', {
      ctx: (state) => state.ctx,
      totalRows: (state) => state.pagination.totalRows,
      filters: (state) => state.filters,
    }),
    quickActions() {
      return [
        {
          value: 'prepare_to_send_to_praticx',
          text: 'Prepara invio a Praticx',
          action: async () => {
            let errorItemsSelected = false;
            let errorMessage = 'Attenzione, selezionare solamente pratiche che ';

            for(const itemSelected of this.itemsSelected) {
              if (itemSelected.praticx_exported !== null) {
                errorItemsSelected = true;
                errorMessage += 'non sono ancora state inviate a Praticx';
              }

              if (errorItemsSelected) {
                this.$dialog.notify.error(errorMessage);
                throw Error();
              }
            }

            await this.updateWithConditions({
              whereConditions: { id: this.itemsSelected.map(item => item.id) },
              updateFields: { praticx_exported: 'new' },
            });
            this.getItems(this.ctx);
          },
        },
      ];
    },
  },
  beforeMount() {
    this.reset();
    this.resetCtx();
    this.getBollatiSummary();
  },
  methods: {
    ...mapActions('payments', ['getItems', 'getBollatiSummary', 'updateWithConditions']),
    ...mapMutations('payments', {
      resetFilters: 'RESET_FILTER_FIELDS',
      resetScope: 'RESET_SCOPES',
      resetCtx: 'RESET_CTX',
      setFilters: 'SET_FILTERS',
      setScopes: 'SET_SCOPES',
    }),
    search() {
      if (this.$refs.filters.exportByType) this.setScopes(['filterExportedByType', 'FnPlacementDetails', 'MpPlacementDetails', 'activity']);
      else this.setScopes(['FnPlacementDetails', 'MpPlacementDetails', 'activity']);
      this.getItems(this.ctx);
      if (this.$refs.filters.exportByType) this.getBollatiSummary(['filterExportedByType']);
      else this.getBollatiSummary([]);
    },
    reset() {
      this.resetFilters();
      this.setScopes(['FnPlacementDetails', 'MpPlacementDetails', 'activity']);
    },
    changeItemsSelected(itemsSelected) {
      this.itemsSelected = itemsSelected;
    },
  }
}
</script>

<style scoped>

</style>
